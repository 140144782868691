<!-- 特殊妇女新增/编辑弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">基本信息</div>
        </div>
        <a-button v-if="isEdit" type="primary" @click="choosePeople"
          >选择居民</a-button
        >
      </div>

      <!-- 基本信息 -->
      <div
        v-if="noChoice"
        style="
          background-color: #fff8de;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        "
      >
        <div style="margin-top: 6px; margin-bottom: 6px">
          &nbsp;
          <a-icon type="info-circle" style="color: #e6a23c" />请选择人员
        </div>
      </div>
      <ax-form v-else ref="form" :formBuilder="formBuilder1">
        <div slot="file">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </div>
      </ax-form>
      <!-- 家庭情况 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">家庭情况</div>
      </div>
      <ax-form
        ref="form1"
        :formBuilder="formBuilder2"
        @change="povertyFlagChange"
      >
        <!-- 配偶姓名 -->
        <div slot="spouseName" style="display: flex">
          <a-input v-model="spouseName" placeholder="请选择"></a-input>
          <ax-button @click="chooseSpouse" style="margin-left: 2%"
            >选择居民</ax-button
          >
        </div>
      </ax-form>
      <!-- 健康情况 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">健康情况</div>
      </div>
      <!-- 健康情况 -->
      <ax-form ref="form2" :formBuilder="formBuilder3"> </ax-form>
      <!-- 其他情况 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">其他情况</div>
      </div>
      <!-- 其他情况 -->
      <ax-form
        ref="form3"
        :formBuilder="formBuilder4"
        @change="organizationChange"
      ></ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="saveType"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择居民弹窗 -->
    <choose-people
      ref="choosePeople"
      @receive="receivePeopleId"
      :selectedPeopleList="selectedPeopleList"
    ></choose-people>
    <!-- 选择配偶弹窗 -->
    <choose-spouse ref="chooseSpouse" @choose="receiveSpouseId"></choose-spouse>
  </div>
</template>
<script>
const formList1 = [
  {
    label: "姓名",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入姓名" }],
  },
  {
    label: "个人照片",
    type: "",
    model: "file",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "" }],
  },
  {
    label: "身份证号",
    type: "",
    model: "identityCard",
    options: { allowClear: true, maxLength: 18, placeholder: "请输入" },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "请输入",
        pattern:
          /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{4}$/ |
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/,
      },
    ],
  },
  {
    label: "性别",
    type: "select",
    model: "gender",
    options: { disabled: true, placeholder: "请先填写身份证号" },
    col: { span: 12 },
    rules: [{ required: false, message: "请先填写身份证号" }],
  },
  {
    label: "年龄",
    type: "",
    model: "age",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "出生日期",
    type: "datePicker",
    model: "birthDate",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "联系电话",
    type: "",
    model: "phone",
    options: { placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "现住区域",
    type: "",
    model: "currentArea",
    options: {},
    col: { span: 24 },
    rules: [{ required: false }],
  },
  {
    label: "详细地址",
    type: "",
    model: "detailedAddress",
    options: { placeholder: "请选择详细地址" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
const formList2 = [
  {
    label: "家庭人口数",
    type: "inputNumber",
    model: "familySize",
    options: {placeholder:'请输入'},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "家庭角色",
    type: "select",
    model: "familyRole",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "配偶姓名",
    type: "",
    model: "spouseName",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "配偶联系电话",
    type: "",
    model: "spousePhone",
    options: {placeholder:'请选择配偶/输入',maxLength:11},
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
  {
    label: "收入来源",
    type: "select",
    model: "incomeSource",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "是否贫困",
    type: "radio",
    model: "povertyFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "贫困等级",
    type: "select",
    model: "povertyLevel",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "是否低保",
    type: "radio",
    model: "allowanceFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
const formList3 = [
  {
    label: "是否自理",
    type: "radio",
    model: "selfCareFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "主要疾病历史",
    type: "",
    model: "diseaseHistory",
    options: { maxLength: 20,placeholder:'请输入'},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "最后一次体检日期",
    type: "datePicker",
    model: "physicalExaminationDate",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },

  {
    label: "体检结果",
    type: "select",
    model: "physicalExaminationResult",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "医保类型",
    type: "select",
    model: "medicalInsuranceType",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "是否存在心理疾病",
    type: "radio",
    model: "mentalIllnessFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
const formList4 = [
  {
    label: "就业情况",
    type: "select",
    model: "employmentStatus",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "是否加入组织",
    type: "radio",
    model: "organizationStatus",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "组织类型",
    type: "select",
    model: "organizationType",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
import api from "./api";
import imagePreview from "./image-preview/imagePreview";
import ChoosePeople from "./choosePeople.vue";
import ChooseSpouse from "./chooseSpouse.vue";
export default {
  components: {
    imagePreview,
    ChoosePeople,
    choosePeopleId: "",
    ChooseSpouse,
  },
  data() {
    return {
      options: [],
      visible: false,
      noChoice: true,
      businessId: "",
      isEdit: true,
      id: "",
      title: "",
      disabled: false,
      fileIds: [], //图片数组
      saveType: false,
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 150, disabled: true },
        { col: { span: 8 } }
      ),
      formBuilder2: this.$common.initGridFormData(
        formList2,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 8 } }
      ),
      formBuilder3: this.$common.initGridFormData(
        formList3,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 12 } }
      ),
      formBuilder4: this.$common.initGridFormData(
        formList4,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 12 } }
      ),
      spouseName: "", //配偶姓名
      baseInfoValue: {}, //基本信息
      familySituation: {}, //家庭情况
      healthCondition: {}, //健康情况
      otherSituations: {}, //其他情况
      povertyLevel: "", //贫困等级
      povertyFlag: "", //是否贫困
      organizationStatus: "", //是否加入组织
      womanInfo: {},
      selectedPeopleList: [],
    };
  },
  watch: {
    businessId(newval) {
      this.businessId = newval;
    },
  },
  computed: {},
  mounted() {},
  methods: {
    // 打开选择居民弹窗
    choosePeople() {
      this.$refs.choosePeople.openModal();
    },
    // 打开选择配偶弹窗
    chooseSpouse() {
      this.$refs.chooseSpouse.openModal();
    },
    // 接收子组件传过来的peopleId
    receivePeopleId(item) {
      if (item[0]) {
        this.noChoice = false;
        this.getPersonInfo(item[0]);
        this.businessId = item[0];
        this.$nextTick(() => {
          this.$refs.img1.getPicList(item[0]);
        });
      }
    },
    receiveSpouseId(item) {
      console.log(item, "398");
      this.spouseName = item.residentName;
      this.$refs.form1.setFieldsValue({ spousePhone: item.phone });
    },
    // 级联选择器改变的回调
    onChange(value) {
      console.log(value);
    },
    // 打开弹窗
    openModal(record = {}) {
      console.log(record, "469");
      this.saveType = false;
      this.selectedPeopleList = record.selectedPeopleList;
      console.log(this.selectedPeopleList, "勾选数据");
      this.visible = true;
      this.noChoice = true;
      this.isEdit = true;
      this.title = record.title;
      this.povertyFlag = "";
      this.povertyLevel = "";
      this.organizationStatus = "";
      this.$nextTick(() => {
        this.spouseName = "";
        this.$refs.form1.resetFields();
        this.$refs.form2.resetFields();
        this.$refs.form3.resetFields();
        this.getDictionsary();
      });
      if (record.id) {
        this.noChoice = false;
        this.isEdit = false;
        this.id = record.id;
        this.businessId = record.residentId;
        this.getPersonInfo(record.residentId);
        this.getWomanInfo(record.id);
        this.$nextTick(() => {
          this.$refs.img1.getPicList(record.residentId);
        });
      } else {
        this.id = "";
      }
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
      this.$refs.form1.resetFields();
      this.$refs.form2.resetFields();
      this.$refs.form3.resetFields();
      this.$emit("refsh");
    },
    // 获取字典
    async getDictionsary() {
      // 特殊妇女家庭角色
      await api.dictData({ dicKind: "woman_family_role" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form1.setFormItemProp("familyRole", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 特殊妇女收入来源
      await api.dictData({ dicKind: "woman_income_source" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form1.setFormItemProp("incomeSource", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 特殊妇女贫困等级
      await api.dictData({ dicKind: "woman_poverty_level" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        if (!this.povertyFlag || this.povertyFlag === "0") {
          console.log(111);
          this.$refs.form1.setFormItemProp("povertyLevel", {
            options: {
              options,
              allowClear: true,
              placeholder: "请选择",
              hide: false,
            },
          });
          this.$refs.form1.setOptions(["allowanceFlag"], "hide", false);
        } else {
          this.$refs.form1.setFormItemProp("povertyLevel", {
            options: {
              hide: true,
            },
          });
        }
        console.log(this.povertyFlag, "499");
      });
      // 特殊妇女体检结果
      await api
        .dictData({ dicKind: "physical_examination_result" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form2.setFormItemProp("physicalExaminationResult", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
      // 特殊妇女医保类型
      await api.dictData({ dicKind: "medical_insurance_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form2.setFormItemProp("medicalInsuranceType", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 特殊妇女就业情况
      await api.dictData({ dicKind: "woman_employment_status" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form3.setFormItemProp("employmentStatus", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 特殊妇女组织类型
      await api.dictData({ dicKind: "woman_organization_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        if (!this.organizationStatus || this.organizationStatus === "0") {
          this.$refs.form3.setFormItemProp("organizationType", {
            options: {
              options,
              allowClear: true,
              placeholder: "请选择",
              hide: false,
            },
          });
        } else {
          this.$refs.form3.setFormItemProp("organizationType", {
            options: {
              allowClear: true,
              placeholder: "请选择",
              hide: true,
            },
          });
        }
      });
    },
    // 贫困等级
    povertyFlagChange(e, type) {
      if (type === "povertyFlag") {
        if (e === "0") {
          this.povertyFlag = e;
          this.getDictionsary();
          this.$refs.form1.setOptions(["povertyLevel"], "hide", false);
          this.$refs.form1.setOptions(["allowanceFlag"], "hide", false);
        } else {
          this.$refs.form1.setOptions(["povertyLevel"], "hide", true);
          this.$refs.form1.setOptions(["allowanceFlag"], "hide", true);
        }
      }
    },
    // 是否加入组织
    organizationChange(e, type) {
      if (type === "organizationStatus") {
        if (e === "0") {
          this.organizationStatus = e
          this.getDictionsary();
          this.$refs.form3.setOptions(["organizationType"], "hide", false);
        } else {
          this.$refs.form3.setOptions(["organizationType"], "hide", true);
        }
      }
    },
    // 身份证号改变的回调
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.$refs.form.setFieldsValue({ birthDate: info.birthDate });
      this.$refs.form.setFieldsValue({ gender: info.gender });
      this.$refs.form.setFieldsValue({ age: info.age });
      return info;
    },
    // 提交
    async onSubmit() {
      if (this.noChoice) {
        this.$message.warning("请先选择人员");
        return;
      }
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        this.baseInfoValue.residentId = this.businessId;
        if (this.$refs.form1) {
          this.$refs.form1.form.validateFields(async (err, value1) => {
            if (err) return;
            this.familySituation = value1;
            this.familySituation.spouseName = this.spouseName;
            this.familySituation.familyRole = value1.familyRole || "";
            this.familySituation.incomeSource = value1.incomeSource || "";
            this.familySituation.povertyLevel = value1.povertyLevel || "";
            this.familySituation.povertyFlag = value1.povertyFlag || "";
            this.familySituation.allowanceFlag = value1.allowanceFlag || "";
            console.log(this.spouseName, "552");
            console.log(this.familySituation, "651");
            if (this.$refs.form2) {
              this.$refs.form2.form.validateFields(async (err, value2) => {
                if (err) return;
                console.log(value2);
                this.healthCondition = value2;
                this.healthCondition.physicalExaminationResult =
                  value2.physicalExaminationResult || "";
                this.healthCondition.medicalInsuranceType =
                  value2.medicalInsuranceType || "";
                this.healthCondition.physicalExaminationDate =
                  value2.physicalExaminationDate || "";
                if (this.$refs.form3) {
                  this.$refs.form3.form.validateFields(async (err, value3) => {
                    if (err) return;
                    this.otherSituations = value3;
                    this.otherSituations.employmentStatus =
                      value3.employmentStatus || "";
                    this.otherSituations.organizationType =
                      value3.organizationType || "";
                    const totalValue = {
                      ...this.baseInfoValue,
                      ...this.familySituation,
                      ...this.healthCondition,
                      ...this.otherSituations,
                    };
                    console.log(totalValue, "375新增数据");
                    this.saveType = true;
                    if (this.id) {
                      totalValue.id = this.id;
                      const res = await api.updateWoman(totalValue);
                      console.log(res);
                      if (res.status === 200) {
                        this.$message.success("修改成功");
                        this.saveType = true;
                        this.closeModal();
                        this.$refs.form1.resetFields();
                        this.$refs.form2.resetFields();
                        this.$refs.form3.resetFields();
                      }
                    } else {
                      const res = await api.addWoman(totalValue);
                      console.log(res);
                      if (res.status === 200) {
                        this.$message.success("新增成功");
                        this.saveType = true;
                        this.closeModal();
                        this.$refs.form1.resetFields();
                        this.$refs.form2.resetFields();
                        this.$refs.form3.resetFields();
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    // 根据ID查询图片
    async picVisitor(id) {
      const res = await api.picVisitor(id);
      console.log(res, "260");
    },
    // 根据id获取详情
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);
      this.$refs.form.setFieldsValue(res.data);
      this.$refs.form.setFieldsValue({
        detailedAddress: res.data.cellName
          ? `${res.data.cellName}/${res.data.unitNum}/${res.data.floorNum}/${res.data.buildingNum}/${res.data.accountNum}`
          : "",
      });
      this.calculateIDCardInfo(res.data.identityCard);
    },
    // 根据id获取特殊妇女详情
    async getWomanInfo(id) {
      const res = await api.getWomanById(id);
      this.womanInfo = res.data;
      this.spouseName = res.data.spouseName;
      this.povertyFlag = res.data.povertyFlag;
      this.organizationStatus = res.data.organizationStatus;
      console.log(this.povertyFlag);
      this.$refs.form1.setFieldsValue(res.data);
      if (this.povertyFlag === "0") {
        this.$refs.form1.setOptions(["allowanceFlag"], "hide", false);
        this.$refs.form1.setOptions(["povertyLevel"], "hide", false);
        this.$refs.form1.setFieldsValue({
          povertyLevel: res.data.povertyLevel,
        });
        this.$refs.form1.setFieldsValue({
          allowanceFlag: res.data.allowanceFlag,
        });
      } else {
        this.$refs.form1.setOptions(["povertyLevel"], "hide", true);
        this.$refs.form1.setOptions(["allowanceFlag"], "hide", true);
      }
      if (this.organizationStatus === "0") {
        this.$refs.form3.setOptions(["organizationType"], "hide", false);
        this.$refs.form3.setFieldsValue({
          organizationType: res.data.organizationType,
        });
      } else {
        this.$refs.form3.setOptions(["organizationType"], "hide", true);
      }
      console.log(this.povertyFlag, "710");
      this.$refs.form2.setFieldsValue(res.data);
      this.$refs.form3.setFieldsValue(res.data);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

.dialog-footer {
  display: flex;
  justify-content: end;
}

.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
</style>